import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";

/* global Excel */

export interface DataView {
  values: ValueColumns;
  categories: any[];
  rowFields: Excel.PivotField[] | string[];
  columnFields: Excel.PivotField[] | string[];
  valueFields: Excel.PivotField[] | string[];
  rowGrandTotal: boolean;
  columnGrandTotal: boolean;
  errorMessage?: string;
  metadata: DataViewMetadata;
  rowNames: string[];
  columnNames: string[];
  valueNames: string[];
}

export interface ValueColumns extends Array<ValueColumn> {
  // values: any[];

  grouped?: () => any;
}

export interface ValueColumn {
  values: any[];
  source?: any;
  minLocal?: number;
  maxLocal?: number;
}

export interface DataViewMetadata {
  columns: DataViewMetadataColumn[];
  measureRoles?: MeasureRoles[];
}

export interface DataViewMetadataColumn {
  /** The user-facing display name of the column. */
  displayName: string;

  /** The query name the source column in the query. */
  queryName?: string;

  /** The format string of the column. */
  format?: string; // TODO: Deprecate this, and populate format string through objects instead.

  /** Data type information for the column. */
  type?: ValueTypeDescriptor;

  /** Indicates that this column is a measure (aggregate) value. */
  isMeasure?: boolean;

  /** The position of the column in the select statement. */
  index?: number;

  /** The properties that this column provides to the visualization. */
  roles?: { [name: string]: boolean };

  /** The name of the containing group. */
  groupName?: PrimitiveValue;
}

export interface ValueTypeDescriptor {
  // Simplified primitive types
  readonly text?: boolean;
  readonly numeric?: boolean;
  readonly integer?: boolean;
  readonly bool?: boolean;
  readonly dateTime?: boolean;
  readonly duration?: boolean;
  readonly binary?: boolean;
  readonly none?: boolean; //TODO: 5005022 remove none type when we introduce property categories.
}

export type PrimitiveValue = string | number | boolean | Date;

export interface DataViewValueColumn extends DataViewCategoricalColumn {
  values: PrimitiveValue[];
  highlights?: PrimitiveValue[];
  identity?: any;
}

export interface DataViewCategoricalColumn {
  source: DataViewMetadataColumn;

  /** The data repetition objects. */
  objects?: any[];
}

export interface DataViewCategoryColumn extends DataViewCategoricalColumn {
  values: PrimitiveValue[];
  identity?: any[];

  /** The set of expressions that define the identity for instances of the category.  This must match items in the DataViewScopeIdentity in the identity. */
  identityFields?: any[];
}

export interface DataViewValueColumnGroup {
  values: DataViewValueColumn[];
  identity?: any;

  /** The data repetition objects. */
  objects?: any;

  name?: PrimitiveValue;
}

export enum ViewMode {
  View = 0,
  Edit = 1,
}

